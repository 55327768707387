import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/gloable.css';
import store from "./store";
import Vuex from 'vuex'

import request from "@/utils/request";

Vue.config.productionTip = false

Vue.use(ElementUI, { size: "mini" });

Vue.prototype.request = request

new Vue({
  router,
  store,
  Vuex,
  render: h => h(App)
}).$mount('#app')

router.beforeEach((to, from, next) => {
  //全局监听是否有提现申请
  if (!window.tips) {
    window.tips = setInterval(() => {
      request
        .get("/api/super_admin/balance/polling", {})
        .then((res) => {
          if (res.success) {
            document.getElementById("audioTip").play();
          }
        });
    }, 5000);
  }

  next();
})